@import url("//fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,600,600i,700,700i");
@import "variables";

body.login {
	background: #FFF;
	font-family: $baseFontFamily;
	h1 {
		a {
			width: 270px;
			height: 100px;
			background: url(../img/logo.png) no-repeat center center / contain;
		}
	}
	form {
		box-shadow: none;
	}
}

#login {
	background: #FFF;
	box-shadow: none;
}

.login #backtoblog,
.login #nav {
	a {
		color: $color1;
		&:hover {
			color: $color1;
			text-decoration: underline;
		}
	}
}

.wp-core-ui {
	.button-primary {
		background: $color1;
		color: #FFF;
		border: none;
		border-radius: 0;
		box-shadow: none;
		text-shadow: none;
		text-transform: uppercase;
		font-weight: 700;
		transition: all 0.2s ease-in-out;
		&:hover,
		&:active,
		&:focus {
			background: $color2 !important;
			outline: 0;
			box-shadow: none !important;
		}
	}
}
