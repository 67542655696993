// GENERAL
// ---------------

$color1:              #000;
$color2:              #999;
$color3:              #231f20;

// TYPOGRAPHY
// ---------------

$textColor:           #231f20;

$baseFontSize:        16px;
$em-base:             $baseFontSize;
$baseLineHeight:      1.612;
$baseTypeSpacing:     round($baseFontSize * $baseLineHeight);
$baseFontFamily:      "Montserrat", Helvetica, Arial, sans-serif;
$secondaryFontFamily: $baseFontFamily;

$headingsFontFamily:  $secondaryFontFamily;
$headingsColor:       #222;

$hrColor:             #DDD;
